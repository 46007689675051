import React from 'react'

function Blog() {
  return (
    <div>
      <h1>This is Blog Component</h1>
    </div>
  )
}

export default Blog
