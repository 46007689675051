import React from 'react'
import Shoes from './Shoes'

function SingleProduct() {
  return (
    <div>
      <Shoes />
    </div>
  )
}

export default SingleProduct
